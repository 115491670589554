import axios from '@axios'

const PREFIX = '/perfil-empresas'

export default {

  get() {
    return axios.get(`${PREFIX}`)
  },

  update(config) {
    return axios.post(`${PREFIX}`, config)
  },
}
