/* eslint-disable no-sequences */
/* eslint-disable no-cond-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
import { extend } from 'vee-validate'
import { cnpj, cpf } from 'cpf-cnpj-validator'

export const is = extend('is', {
  validate(value, args) {
    return {
      required: true,
      valid: args[0] && args[0] === 'true',
    }
  },
  computesRequired: true,
  message: '{_field_} é inválido!',
})

export const listRequired = extend('listRequired', {
  validate(value) {
    return {
      required: true,
      valid: value && value.length > 0,
    }
  },
  computesRequired: true,
  message: '{_field_} é obrigatório!',
})

export const possuiId = extend('possuiId', {
  validate(value) {
    return {
      required: true,
      valid: value.id > 0,
    }
  },
  computesRequired: true,
  message: '{_field_} inválido',
})

export const dinheiroObrigatorio = extend('dinheiroObrigatorio', {
  validate(value) {
    let valorPtBR = value
    if (valorPtBR) {
      if (value.indexOf('R$') >= 0) {
        const valorReais = value.replace('R$', '').trim()
        valorPtBR = valorReais.replace('.', '').replace(',', '.')
      }
    }
    return {
      required: true,
      valid: valorPtBR && parseFloat(valorPtBR.replace('.', '').replace(',', '.')) > 0,
    }
  },
  computesRequired: true,
  message: '{_field_} deve ser maior que zero!',
})

export const cpfDocumento = extend('cpfDocumento', {
  validate(value) {
    return {
      required: true,
      valid: cpf.isValid(value),
    }
  },
  computesRequired: false,
  message: '{_field_} deve ser válido!',
})

export const cnpjDocumento = extend('cnpjDocumento', {
  validate(value) {
    return {
      required: true,
      valid: cnpj.isValid(value),
    }
  },
  computesRequired: true,
  message: '{_field_} deve ser válido!',
})

export const numberRequired = extend('numberRequired', {
  validate(value) {
    return {
      required: true,
      valid: value && value > 0,
    }
  },
  computesRequired: true,
  message: '{_field_} é obrigatório!',
})

export const objectRequired = extend('objectRequired', {
  validate(value) {
    return {
      required: true,
      valid: value && Object.keys(value) && Object.keys(value).length > 0,
    }
  },
  computesRequired: true,
  message: '{_field_} é obrigatório!',
})

const validaTIT = titulo => {
  let handler = titulo.replaceAll(' ', '').replaceAll('_', '')
  if (handler.length > 11) {
    let e = 0
    let t = 0
    let a = handler.length
    const d = handler.substr(a - 2, 2)
    let c = handler.substr(a - 4, 2)
    handler = handler.substr(0, a - 2)
    a = c === '01' || c === '02'
    c = (e = 9 * (handler.charCodeAt(0) - 48) + 8 * (handler.charCodeAt(1) - 48) + 7 * (handler.charCodeAt(2) - 48) + 6 * (handler.charCodeAt(3) - 48) + 5 * (handler.charCodeAt(4) - 48) + 4 * (handler.charCodeAt(5) - 48) + 3 * (handler.charCodeAt(6) - 48) + 2 * (handler.charCodeAt(7) - 48)) % 11

    return e = c === 0 ? a ? 1 : 0 : c === 1 ? 0 : 11 - c,
    t = (c = (t = 4 * (handler.charCodeAt(8) - 48) + 3 * (handler.charCodeAt(9) - 48) + 2 * e) % 11) === 0 ? a ? 1 : 0 : c === 1 ? 0 : 11 - c,
    d.charCodeAt(0) - 48 === e && d.charCodeAt(1) - 48 === t
  }
  return false
}

export const tituloDocumento = extend('tituloDocumento', {
  validate(value) {
    return {
      required: true,
      valid: value && validaTIT(value),
    }
  },
  computesRequired: false,
  message: '{_field_} deve ser válido!',
})

const validaNomeCompleto = nome => {
  const regex = /^[A-Za-zÀ-ÿ\s]+$/
  if (!regex.test(nome)) {
    return false
  }

  const palavras = nome.trim().split(/\s+/)
  if (palavras.length < 2) {
    return false
  }

  if (palavras.some(p => p.length < 2)) {
    return false
  }

  return true
}

export const nomeCompleto = extend('nomeCompleto', {
  validate(value) {
    return {
      required: true,
      valid: value && validaNomeCompleto(value),
    }
  },
  computesRequired: false,
  message: '{_field_} deve ser válido!',
})

const validarDiaPeriodo = dia => {
  if (dia < 1 || dia > 31 || dia % 1 !== 0 || dia.length > 2) {
    return false
  }

  return true
}

export const diaValido = extend('diaValido', {
  validate(value) {
    return {
      required: true,
      valid: validarDiaPeriodo(value),
    }
  },
  computesRequired: false,
  message: '{_field_} a data deve ser válida!',
})
