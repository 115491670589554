<template>
  <div>
    <cadastro-base
      :salvar="salvar"
      :cancelar="cancelar"
    >
      <template slot="conteudo">
        <b-row>
          <b-form-group
            label="Razão Social"
            class="col-md-6"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Razão social"
            >
              <b-form-input
                v-model="form.razaoSocial"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-col cols="3">
            <b-form-group label="CNPJ/CPF">
              <validation-provider
                #default="{ errors }"
                :rules="'required|' + validacao"
                name="CNPJ/CPF"
              >
                <masked-input
                  v-model="form.cnpj"
                  :mask="mascaraCadastroFederal"
                  class="form-control"
                  :disabled="disableCnpj"
                  placeholder-char=" "
                  placeholder="CNPJ ou CPF para cadastro"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Plano">
              <masked-input
                :disabled="true"
                class="form-control"
                :placeholder="form.tipoPlano === 3 ? 'Plano Exclusivo' : form.tipoPlano === 2 ? 'Plano Ideal' : 'Plano Essencial'"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-form-group
            label="E-mail"
            class="col-md-12"
          >
            <validation-provider
              #default="{ errors }"
              rules="required|email"
              name="E-mail"
            >
              <b-form-input v-model="form.email" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-row>

        <b-row>
          <b-col cols="12">
            <upload-e-visualizacao
              ref="upload"
              :value="form.logo"
            />
          </b-col>
        </b-row>
      </template>
    </cadastro-base>
  </div>
</template>

<script>
import { cpfDocumento, cnpjDocumento } from '@pilar/plugins/p-validation/p-validation'
import { ValidationProvider } from 'vee-validate'
import {
  required,
} from '@validations'
import CadastroBase from '@pilar/components/cadastro-base/CadastroBase.vue'
import api from './shared/services/api'

export default {
  name: 'Proprietario',
  components: {
    CadastroBase,
    ValidationProvider,
    UploadEVisualizacao: () => import('@pilar/plugins/upload-e-visualizacao/UploadEVisualizacao.vue'),
  },
  data() {
    return {
      cpfDocumento,
      cnpjDocumento,
      required,
      form: {
        id: null,
        cnpj: null,
        razaoSocial: null,
        email: null,
        logo: null,
      },
      immutableForm: {},
      maskApplied: 0,
      isAlteracao: false,
      disableCnpj: true,
      validacao: '',
    }
  },
  created() {
    this.carregar()
  },
  methods: {
    carregar() {
      api.get()
        .then(payload => {
          this.form = payload.data
          if (!this.form) {
            this.form = {}
          } else {
            this.isAlteracao = true

            this.setDisableCnpj()
          }
        })
        .catch(err => {
          throw err
        })
    },
    salvar() {
      return new Promise((resolve, reject) => {
        const form = { ...this.form }
        form.logo = null

        const formData = this.$refs.upload.getFormDataBody(form)
        api.update(formData)
          .then(payload => {
            const acao = this.isAlteracao ? 'alterado' : 'cadastrado'
            resolve(`Perfil empresa ${acao} com sucesso`)

            this.form.id = payload.data
            this.isAlteracao = !this.isAlteracao ? true : this.isAlteracao

            this.setDisableCnpj()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    setDisableCnpj() {
      this.disableCnpj = !!(this.form.cnpj && this.form.cnpj !== '')
      this.mascaraCadastroFederal()
    },
    cancelar() {
      this.$router.push({ name: 'dashboard-analitico' })
    },
    isCnpj() {
      return this.form.cnpj
        && this.form.cnpj
          .replaceAll(' ', '')
          .replaceAll('.', '')
          .replace('-', '')
          .replace('/', '').length > 11
    },
    mascaraCadastroFederal() {
      if (this.isCnpj()) {
        this.validacao = 'cnpjDocumento'
        return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
      }

      this.validacao = 'cpfDocumento'
      return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
    },
  },
}
</script>
