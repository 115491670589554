<template>
  <b-card
    no-body
    style="height: calc(100%);"
  >
    <b-card-body>
      <validation-observer ref="validacao">
        <b-form>
          <slot name="conteudo" />
        </b-form>
      </validation-observer>
    </b-card-body>
    <b-card-footer>
      <b-row no-gutters>
        <b-col class="text-right">
          <b-btn
            variant="none"
            class="btn-outline-success mr-50"
            @click="salvarCadastro"
          >{{ isEditar ? 'Salvar' : 'Editar' }}</b-btn>
        </b-col>
        <b-btn
          variant="none"
          class="btn-outline-danger"
          @click="cancelar"
        >Cancelar</b-btn>
      </b-row>
    </b-card-footer>
    <loading
      :show-cancel-button="showLoadingCancelButton"
      :is-loading="isLoading"
      titulo-carregamento="Aguarde!"
    />
  </b-card>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'

export default {
  name: 'CadastroBase',
  components: {
    ValidationObserver,
    Loading: () => import('@pilar/components/loading/Loading.vue'),
  },
  props: {
    salvar: {
      type: Function,
      required: true,
    },
    cancelar: {
      type: Function,
      required: true,
    },
    goBack: {
      type: [Boolean, String],
      default: () => false,
    },
    showLoadingCancelButton: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isEditar: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    localize('ptBR')
  },
  methods: {
    salvarCadastro() {
      if (!this.isEditar) {
        this.chamarSalvarCadastro()
      } else if (Object.values(this.$refs.validacao.errors).some(some => some.length > 0)) {
        this.$erro('Preencha os campos corretamente', 'danger')
      } else {
        this.$refs.validacao
          .validate()
          .then(success => {
            if (success) {
              this.chamarSalvarCadastro()
            } else {
              this.$erro('Preencha os campos corretamente', 'danger')
            }
          })
      }
    },
    chamarSalvarCadastro() {
      const promisse = this.salvar()
      if (promisse) {
        promisse
          .then(mensagem => {
            this.$sucesso(mensagem, 'success')
          })
          .catch(err => {
            this.$erro(err.message, 'danger')
            if (!err.response || (err.response && (err.response.status !== 500 || err.response.status !== 400))) {
              this.$rputer.go(-1)
            }
            throw err
          })
      }
    },
  },
}
</script>
